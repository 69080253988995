<template>
  <div class="PersonalPerfil">
    <v-card
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title v-text="'Información general'" />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-text-field
              filled
              label="Nombre*"
              v-model="persona.nombre_pac"
              :error-messages="errors.nombre_pac"
              disabled
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              filled
              label="Apellido paterno*"
              v-model="persona.ape_pat_pac"
              :error-messages="errors.ape_pat_pac"
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              filled
              label="Apellido materno*"
              v-model="persona.ape_mat_pac"
              :error-messages="errors.ape_mat_pac"
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              filled
              :items="sexos"
              label="Sexo"
              v-model="persona.sexo_pac"
              :error-messages="errors.sexo_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <date-picker
              label="Cumpleaños"
              v-model="persona.fecha_nac_pac"
              :error="errors.fecha_nac_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              filled
              label="CURP"
              v-model="persona.curp_pac"
              v-mask="'AAAA######AAAAAA##'"
              :error-messages="errors.curp_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              filled
              label="NSS"
              v-model="persona.nss_per"
              :error-messages="errors.nss_per"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              filled
              label="RFC"
              v-mask="'AAAA######NNN'"
              v-model="persona.rfc_pac"
              :error-messages="errors.rfc_pac"
            />
          </v-col>

        </v-row>

      </v-card-text>
      <v-card-title v-text="'Información demográfica'" />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              filled
              label="Calle"
              v-model="persona.calle_pac"
              :error-messages="errors.calle_pac"
            />
          </v-col>
          <v-col
            cols="6"
            sm="3"
          >
            <v-text-field
              filled
              label="# ext."
              v-model="persona.num_ext_pac"
              :error-messages="errors.num_ext_pac"
            />
          </v-col>
          <v-col
            cols="6"
            sm="3"
          >
            <v-text-field
              filled
              label="# int."
              v-model="persona.num_int_pac"
              :error-messages="errors.num_int_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              filled
              label="colonia"
              v-model="persona.colonia_pac"
              :error-messages="errors.colonia_pac"
            />
          </v-col>
          <v-col
            cols="6"
            sm="3"
          >
            <v-text-field
              filled
              label="Código Postal"
              v-model="persona.cp_pac"
              :error-messages="errors.cp_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <autocomplete-data-estados
              label="Estado"
              v-model="persona.id_edo_pac"
              :error="errors.id_edo_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <autocomplete-data-municipios
              label="Municipio"
              v-model="persona.id_mpio_pac"
              :estado="persona.id_edo_pac"
              :error="errors.id_mpio_pac"
            />
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-title v-text="'Información de contacto'" />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              prepend-inner-icon="phone_iphone"
              filled
              hint="Con este número es con el cual podrás acceder a ver tu información en Cruz Roja"
              label="Teléfono móvil"
              persistent-hint
              v-mask="'##########'"
              v-model="persona.cel_pac"
              :error-messages="errors.cel_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              prepend-inner-icon="phone"
              filled
              label="Teléfono fijo"
              v-mask="'##########'"
              v-model="persona.tel_pac"
              :error-messages="errors.tel_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-text-field
              prepend-inner-icon="alternate_email"
              filled
              label="Correo electrónico"
              v-model="persona.email_pac"
              :error-messages="errors.email_pac"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="_updatePersona"
        >Actualizar información</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import forms from "@/mixins/forms";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
import AutocompleteDataEstados from "@/components/forms/autocompletes/AutocompleteDataEstados.vue";
import AutocompleteDataMunicipios from "@/components/forms/autocompletes/AutocompleteDataMunicipios.vue";
export default {
  name: "PersonalPerfil",
  mixins: [forms],
  components: {
    DatePicker,
    AutocompleteDataEstados,
    AutocompleteDataMunicipios,
  },
  data() {
    return {
      persona: {
        ape_mat_pac: null,
        ape_pat_pac: null,
        calle_pac: null,
        cel_pac: null,
        colonia_pac: null,
        cp_pac: null,
        curp_pac: null,
        email_pac: null,
        fecha_nac_pac: null,
        id_edo_pac: null,
        id_mpio_pac: null,
        nombre_pac: null,
        nss_per: null,
        num_ext_pac: null,
        num_int_pac: null,
        rfc_pac: null,
        sexo_pac: null,
        tel_pac: null,
      },
      errors: {
        ape_mat_pac: null,
        ape_pat_pac: null,
        calle_pac: null,
        cel_pac: null,
        colonia_pac: null,
        cp_pac: null,
        curp_pac: null,
        email_pac: null,
        fecha_nac_pac: null,
        id_edo_pac: null,
        id_mpio_pac: null,
        nombre_pac: null,
        num_ext_pac: null,
        rfc_pac: null,
        sexo_pac: null,
      },
    };
  },
  methods: {
    _getPersona() {
      this._getThings("personal/perfil", "persona");
    },
    _updatePersona() {
      const data = { persona: this.persona };
      this._validateAll("persona")
        ? this._updateAll(
            "personal/perfil",
            data,
            "msg",
            "_handleResponsePersona"
          )
        : this.$emit("msg", this.$lleneCampos);
    },
    _handleResponsePersona(res) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.$emit("msg", res.msg);
    },
  },
  created() {
    this._getPersona();
  },
};
</script>